import { 
  READ_ACTION, 
  DIGITAL_FRANCHISE_READ,
  MANAGE_ACTION, 
  NPS_SURVEY
 } from "@/constants/resources";

export default [
  {
    title: "NPS",
    icon: "none",
    resource: NPS_SURVEY,
    action: READ_ACTION,
    children: [
      {
        title: "Meus NPS",
        icon: "UserIcon",
        route: "nps-consultant",
        resource: NPS_SURVEY,
        action: READ_ACTION,
      },
      {
        title: "NPS da estrutura",
        icon: "UsersIcon",
        route: "nps-structure",
        resource: NPS_SURVEY,
        action: DIGITAL_FRANCHISE_READ,
      },
      {
        title: "NPS das franquias",
        icon: "TrendingUpIcon",
        route: "nps-franchises",
        resource: NPS_SURVEY,
        action: MANAGE_ACTION,
      },
    ],
  },
];
