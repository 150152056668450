import {
  READ_ACTION,
  CONTRACT_ADMINISTRATION,
  CONTRACT_RESOURCE,
  BUSINESS_INTELLIGENCE_RESOURCE,
  UPDATE_ACTION,
  FINANCIAL_SOLUTION_RESOURCE,
  PARTNER_COMPANY_RESOURCE,
  PRODUCT_RESOURCE
} from "@/constants/resources";

export default [
  {
    title: "Contratos",
    icon: "none",
    resource: CONTRACT_RESOURCE,
    action: READ_ACTION,
    children: [
      {
        title: "Novo contrato",
        route: "contract-save",
        icon: "PlusCircleIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Meus contratos",
        route: "contracts-list",
        icon: "FileTextIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "ADM contratos",
        route: "contract-administration",
        icon: "ClipboardIcon",
        resource: BUSINESS_INTELLIGENCE_RESOURCE,
        action: CONTRACT_ADMINISTRATION,
      },
      {
        title: "Cotações",
        href: "https://docs.google.com/spreadsheets/d/1DxL2kfCJhHLhzKXjh6lsw604GAxGnWw9okY8s97b61I/edit#gid=229014797",
        icon: "TrelloIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Soluções financeiras",
        route: "financial-solutions-list",
        icon: "CheckCircleIcon",
        resource: FINANCIAL_SOLUTION_RESOURCE,
        action: UPDATE_ACTION,
      },
      {
        title: "Empresas",
        route: "companies-list",
        icon: "HomeIcon",
        resource: PARTNER_COMPANY_RESOURCE,
        action: UPDATE_ACTION,
      },
      {
        title: "Produtos",
        route: "products-list",
        icon: "BoxIcon",
        resource: PRODUCT_RESOURCE,
        action: UPDATE_ACTION,
      },
    ],
  },
];
