import {
  BUSINESS_INTELLIGENCE_RESOURCE,
  CONTRACT_RESOURCE,
  READ_ACTION,
  BUSINESS_SCHOOL_PROGRESS,
} from "@/constants/resources";

export default [
  // {
  //   header: "Educação",
  //   resource: CONTRACT_RESOURCE,
  //   action: READ_ACTION,
  // },
  {
    title: "Educação",
    icon: "none",
    resource: CONTRACT_RESOURCE,
    action: READ_ACTION,
    children: [
      {
        title: "Escola de negócios",
        href: "https://escoladenegocios.consultoriafuturo.com.br/courses?tenant=parceiro",
        icon: "BookOpenIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Progresso escola de negócios",
        route: "business-school-progress",
        icon: "AwardIcon",
        resource: BUSINESS_INTELLIGENCE_RESOURCE,
        action: BUSINESS_SCHOOL_PROGRESS,
      },
    ],
  },
];



