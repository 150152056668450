import { READ_ACTION, CONTRACT_RESOURCE } from "@/constants/resources";

export default [
  // {
  //   header: "Outros",
  //   resource: CONTRACT_RESOURCE,
  //   action: READ_ACTION,
  // },
  {
    title: "Departamentos",
    icon: "none",
    resource: CONTRACT_RESOURCE,
    action: READ_ACTION,
    children: [
      {
        title: "Responsáveis dos Departamentos",
        route: "misc-department-members",
        icon: "TrelloIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Organograma",
        href: "https://www.canva.com/design/DAFA5MuTqJM/H5YYbjhzbFPqaE98xx66lA/view",
        icon: "BarChart2Icon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
    ],
  },
];
