import {
  LEAD_RESOURCE,
  CUSTOMER_RESOURCE,
  CONSULTANT_RESOURCE,
  CAMPAIGN,
  READ_ACTION,
  CREATE_ACTION,
  BUSINESS_INTELLIGENCE_RESOURCE,
  PENDING_INTERVIEWS_ACTION,
  TRANSFER_ACTION,
  OPPORTUNITY_MAP_ACTION
} from "@/constants/resources";

export default [
  // {
  //   header: "Gestão de clientes",
  //   resource: LEAD_RESOURCE,
  //   action: READ_ACTION,
  // },
  {
    title: "Gestão de clientes",
    icon: "none",
    resource: LEAD_RESOURCE,
    action: READ_ACTION,
    children: [
      // 2nd level
      {
        title: "Leads clientes",
        route: "leads-customers-list",
        icon: "UserPlusIcon",
        resource: LEAD_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Transferir leads",
        route: "leads-transfer",
        icon: "RepeatIcon",
        resource: LEAD_RESOURCE,
        action: TRANSFER_ACTION,
      },
      {
        title: "Clientes",
        route: "customers-list",
        icon: "UserIcon",
        resource: CUSTOMER_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Transferir clientes",
        route: "customers-transfer",
        icon: "RefreshCwIcon",
        resource: CUSTOMER_RESOURCE,
        action: TRANSFER_ACTION,
      },
      {
        title: "Compromissos",
        route: "appointments",
        icon: "CalendarIcon",
        resource: LEAD_RESOURCE,
        action: CREATE_ACTION,
      },
      {
        title: "Campanhas",
        route: "campaigns",
        icon: "ShoppingBagIcon",
        resource: CAMPAIGN,
        action: READ_ACTION,
      },
      {
        title: "Mapa de oportunidades",
        route: "opportunity-map-data-studio",
        icon: "MapIcon",
        resource: CONSULTANT_RESOURCE,
        action: OPPORTUNITY_MAP_ACTION,
      },
      {
        title: "EFs sem EP",
        route: "pending-interviews-data-studio",
        icon: "InfoIcon",
        resource: BUSINESS_INTELLIGENCE_RESOURCE,
        action: PENDING_INTERVIEWS_ACTION,
      },
    ],
  },
];
