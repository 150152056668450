import {
  LEAD_RESOURCE,
  CUSTOMER_RESOURCE,
  READ_ACTION,
  CREATE_ACTION,
  BUSINESS_INTELLIGENCE_RESOURCE,
  PENDING_INTERVIEWS_ACTION,
  TRANSFER_ACTION,
} from "@/constants/resources";

export default [
  // {
  //   header: "Gestão de clientes",
  //   resource: LEAD_RESOURCE,
  //   action: READ_ACTION,
  // },
  {
    title: "Franquias",
    icon: "none",
    resource: LEAD_RESOURCE,
    action: READ_ACTION,
    children: [
      // 2nd level
      {
        title: "Franquias físicas",
        route: "physical-franchise-list",
        icon: "HomeIcon",
        resource: LEAD_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Franquias digitais",
        route: "franchisees-list",
        icon: "MousePointerIcon",
        resource: LEAD_RESOURCE,
        action: TRANSFER_ACTION,
      },
    ],
  },
];
