import { ANNOUNCEMENT_RESOURCE, DASHBOARD_BANNER, READ_ACTION } from "@/constants/resources";

export default [
  {
    title: "Configurações",
    icon: "none",
    resource: ANNOUNCEMENT_RESOURCE,
    action: READ_ACTION,
    children: [
      {
        title: "Gerenciamento de banners",
        route: "dashboard-banners",
        icon: "ImageIcon",
        resource: DASHBOARD_BANNER,
        action: READ_ACTION,
      },
    ],
  },
];
