import {
  CONTRACT_RESOURCE,
  WITHDRAWAL_RESOURCE,
  FINANCIAL_ADJUSTMENTS,
  CREATE_ACTION,
  READ_ACTION,
  MANAGE_ACTION,
  FINANCIAL_MANAGMENT,
  PAYMENT_IMPORT,
  COMMISSION_MANAGEMENT,
  EXPENSE
} from "@/constants/resources";

export default [
  // {
  //   header: "Financeiro",
  //   resource: CONTRACT_RESOURCE,
  //   action: CREATE_ACTION,
  // },
  {
    title: "Comissões",
    icon: "none",
    children: [
      {
        title: "Minhas Comissões",
        route: "commissions",
        icon: "DollarSignIcon",
        resource: CONTRACT_RESOURCE,
        action: CREATE_ACTION,
      },
      {
        title: "Meus pagamentos",
        route: "withdrawal-list",
        icon: "CreditCardIcon",
        resource: WITHDRAWAL_RESOURCE,
        action: READ_ACTION,
      }
    ],
  },
    {
    title: "Financeiro",
    icon: "none",
    resource: FINANCIAL_MANAGMENT,
    action: READ_ACTION,
    children: [
      {
        title: "Lançamentos",
        route: "financial-adjustments-list",
        icon: "Edit3Icon",
        resource: FINANCIAL_ADJUSTMENTS,
        action: READ_ACTION,
      },
      {
        title: "Contas a pagar",
        route: "financial-management-payables",
        icon: "BookIcon",
        resource: FINANCIAL_MANAGMENT,
        action: READ_ACTION,
      },
      {
        title: "Receitas",
        route: "commission-management",
        icon: "DollarSignIcon",
        resource: COMMISSION_MANAGEMENT,
        action: MANAGE_ACTION,
      },
      {
        title: "Despesas",
        route: "expenses-list",
        icon: "DivideIcon",
        resource: EXPENSE,
        action: READ_ACTION,
      },
      {
        title: "Importação de receitas",
        route: "payment-importer",
        icon: "UploadIcon",
        resource: PAYMENT_IMPORT,
        action: READ_ACTION,
      },
    ],
  },
];
